import type { AccountType, RelyingParty, RelyingPartyAdminType } from './graphql';

export type FormValues = {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  address?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressState?: string;
  addressCity?: string;
  email?: string;
  zipcode?: string;
  ehrId?: string;
  healthPlan?: string;
  phone?: string;
  birthDate?: Date | null;
  gender?: string;
  consent?: string;
  emergencyContactName?: string;
  emergencyContactEmail?: string;
  emergencyContactPhone?: string;
  doNotCall?: boolean;
  primaryLanguage?: string;
  doNotCallLastSpecifiedAt?: Date | null;
};

export type User = {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
};

export type AccountComment = {
  id: string;
  body: string;
  createdAt: string;
  sender: {
    name: string;
    email: string;
  };
};

export interface GetAccountCommentsResponse {
  commentsByAccount: {
    comments: AccountComment[];
    meta: {
      totalPages: number;
      totalCount: number;
    };
  };
}

export type Profile = {
  addressLine1?: string;
  addressLine2?: string;
  addressState?: string;
  addressCity?: string;
  zipcode?: string;
  healthPlan?: string;
  phone?: string;
  consent?: string;
  birthDate?: string;
  gender?: string;
  emergencyContactName?: string;
  emergencyContactEmail?: string;
  emergencyContactPhone?: string;
  userProfile?: User;
};

export type HealthPlan = {
  id: string;
  orgName: string;
};

export type FollowUpRemindersTypes = {
  id: string;
  followUpOn: string;
  type: string;
  subType: string;
  notes: string;
  status: string;
  relyingPartyAdmin: RelyingPartyAdminType;
  relyingParty: RelyingParty;
  createdAt: string;
  account: AccountType;
};

type FollowupFormHiddenFields = {
  type: boolean;
  subType: boolean;
  status: boolean;
};

type FollowupFormCustomLabels = {
  performedDate: string;
  performedTime: string;
  type: string;
  assignee: string;
  subType: string;
  status: string;
  note: string;
};

export type InitialVisitFollowupCustomTypes = {
  hiddenFields: Partial<FollowupFormHiddenFields>;
  customLabels: Partial<FollowupFormCustomLabels>;
};

export const GENDER_OPTIONS_ARRAY = ['Male', 'Female'];

export const GENDER_OPTIONS = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' }
];

export const CONSENT_OPTIONS = [
  { label: 'Patient has not consented', value: 'NotProvided' },
  { label: 'Patient has provided consent', value: 'PatientAnyProvider' },
  { label: "Patient's guardian has provided consent", value: 'GuardianAnyProvider' }
];

export enum AccountNameOrder {
  FIRST_LAST = 'first-last',
  LAST_FIRST = 'last-first'
}
